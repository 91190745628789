import React from "react";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { sanitizeHTML } from "utils/sanitizeHTML";

interface SanitizedTypographyProps extends TypographyProps {
  dangerouslySetInnerHTML: { __html: string };
  variant?: TypographyProps["variant"];
  component?: React.ElementType;
  sx?: TypographyProps["sx"];
}

const SanitizedTypography: React.FC<SanitizedTypographyProps> = ({
  dangerouslySetInnerHTML,
  variant,
  component,
  sx,
  ...props
}) => {
  const sanitizedHTML = sanitizeHTML(dangerouslySetInnerHTML.__html);

  const typographyProps: TypographyProps = {
    ...(variant && { variant }),
    ...(component && { component }),
    ...(sx && { sx }),
    dangerouslySetInnerHTML: { __html: sanitizedHTML },
    ...props,
  };

  return <Typography {...typographyProps} />;
};

export default SanitizedTypography;
