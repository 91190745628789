import React, { useState, useMemo } from "react";
import { Grid2 as Grid, Link } from "@mui/material";
import { useTheme } from "@mui/system";
import {
  BreathIcon,
  HumidityIcon,
  TemperatureIcon,
  GearIcon,
  CloudIcon,
  SensorIcon,
  ConnectivityIcon,
  Co2Icon,
} from "components/icons";
import Tooltip from "components/Tooltip";
import {
  StyledTypography,
  iconStyle,
  StatusAnimation,
  StyledPreCheckGrid,
  StyledPreCheckTypography,
} from "../Devices.styles";
import { DeviceStateEnum, TroubleshootGuideContextTag } from "constants/index";
import Help from "components/Help";
import useGuideStore from "components/TroubleShootGuide/guide.store";
import { SignalIcon } from "components/icons/SignalIcon";
import { CardStatus, getCardColor } from "components/Card";
import { removePrecision } from "utils/number.utils";

export interface Rule {
  name: string;
  background: Backgrounds;
  verdict: boolean;
  tooltip?: string;
  showValueOnHoover?: boolean;
  showValue?: boolean;
  signalStrength?: boolean;
  status?: CardStatus;
  results?: any;
}

interface PrechecksIconsProps {
  rules: Rule[];
  state: string;
  contextTag?: string[];
}

export enum Backgrounds {
  breath = "breath",
  humidity = "humidity",
  temperature = "temperature",
  gear = "gear",
  cloud = "cloud",
  sensor = "sensor",
  connection = "connection",
  co2 = "co2",
  noSignal = "noSignal",
  lowSignal = "lowSignal",
  excellentSignal = "excellentSignal",
  goodSignal = "goodSignal",
  none = "none",
}

const ICON_COMPONENTS: { [key in Backgrounds]: React.ElementType } = {
  [Backgrounds.temperature]: TemperatureIcon,
  [Backgrounds.humidity]: HumidityIcon,
  [Backgrounds.sensor]: SensorIcon,
  [Backgrounds.connection]: ConnectivityIcon,
  [Backgrounds.breath]: BreathIcon,
  [Backgrounds.gear]: GearIcon,
  [Backgrounds.cloud]: CloudIcon,
  [Backgrounds.co2]: Co2Icon,
  [Backgrounds.noSignal]: SignalIcon,
  [Backgrounds.lowSignal]: SignalIcon,
  [Backgrounds.excellentSignal]: SignalIcon,
  [Backgrounds.goodSignal]: SignalIcon,
  [Backgrounds.none]: React.Fragment,
};

const PrechecksIcons: React.FC<PrechecksIconsProps> = ({
  rules,
  state,
  contextTag = [],
}) => {
  const theme = useTheme();
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const { setContextTag, setOpen: setGuideOpen } = useGuideStore();

  const openHelp = () => {
    if (contextTag.length) setContextTag(contextTag);
    setGuideOpen(true);
  };

  const isDeviceReadyOrPreparing = useMemo(
    () =>
      [DeviceStateEnum.Ready, DeviceStateEnum.Preparing].includes(
        state as DeviceStateEnum
      ),
    [state]
  );

  const handleMouseEnter = (index: number) => setHoveredIndex(index);
  const handleMouseLeave = () => setHoveredIndex(null);

  const renderIcon = useMemo(
    () => (rule: Rule, index: number) => {
      const IconComponent = ICON_COMPONENTS[rule.background] || React.Fragment;
      const value = rule?.results[rule.name]?.value;
      const displayValue = Array.isArray(value)
        ? value.reduce((acc, curr) => acc + curr, 0) / value.length
        : value;

      const cardColor = isDeviceReadyOrPreparing
        ? rule?.signalStrength && rule?.status
          ? getCardColor(rule.status, theme).main
          : theme.palette.success.main
        : theme.palette.pending.dark;

      const backgroundColor = rule?.signalStrength
        ? cardColor
        : rule?.verdict && isDeviceReadyOrPreparing
        ? theme.palette.success.main
        : theme.palette.pending.dark;

      const isPreparing = !rule.verdict && state === DeviceStateEnum.Preparing;
      const icon = <IconComponent sx={iconStyle(theme, backgroundColor)} />;

      const content = contextTag.includes(rule.name) ? (
        <Link onClick={openHelp} sx={{ cursor: "pointer" }}>
          <StyledTypography
            component="span"
            sx={{ backgroundColor, width: "2.75rem", height: "2.75rem" }}
          >
            {isPreparing ? <StatusAnimation>{icon}</StatusAnimation> : icon}
          </StyledTypography>
        </Link>
      ) : (
        <StyledTypography
          component="span"
          sx={{ backgroundColor, width: "2.75rem", height: "2.75rem" }}
        >
          {isPreparing ? <StatusAnimation>{icon}</StatusAnimation> : icon}
        </StyledTypography>
      );

      const showValue =
        (rule.showValueOnHoover ? hoveredIndex === index : true) &&
        rule.showValue &&
        state !== DeviceStateEnum.InUse;

      return (
        <Tooltip title={rule.tooltip || ""} key={`${rule.name}_${index}`}>
          <StyledPreCheckGrid
            container
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            <Grid>{content}</Grid>
            {showValue && (
              <Grid>
                <StyledPreCheckTypography>
                  {removePrecision(displayValue)}
                </StyledPreCheckTypography>
              </Grid>
            )}
          </StyledPreCheckGrid>
        </Tooltip>
      );
    },
    [hoveredIndex, isDeviceReadyOrPreparing, contextTag, theme]
  );

  return (
    <Grid container justifyContent="flex-start">
      {rules.map(renderIcon)}
      {contextTag.length > 0 && (
        <Help
          contextTag={
            state === DeviceStateEnum.Unavailable
              ? [TroubleshootGuideContextTag.DEVICE_STATUS]
              : state === DeviceStateEnum.Preparing
              ? [TroubleshootGuideContextTag.DEVICE]
              : [...contextTag, TroubleshootGuideContextTag.VALIDATOR]
          }
        />
      )}
    </Grid>
  );
};

export default PrechecksIcons;
