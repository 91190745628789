import { create } from "zustand";
import { post } from "utils/publicHttp.utils";
import { ConfigurationService } from "services/configuration.service";
import { AuthService } from "services/auth.service";

export interface ReportIssueFormData {
  title: string;
  description: string;
}

interface ReportIssueStore {
  open: boolean;
  setOpen: (open: boolean) => void;
  submitIssue: (payload: ReportIssueFormData) => Promise<any>;
  serialNumbers: string[];
  setSerialNumbers: (serialNumbers: string[]) => void;
}

const useReportIssueStore = create<ReportIssueStore>((set, get) => ({
  open: false,
  serialNumbers: [],
  setSerialNumbers: (serialNumbers) => set({ serialNumbers }),
  setOpen: (open) => set({ open }),
  submitIssue: async (payload: ReportIssueFormData) => {
    try {
      const configuration = ConfigurationService.configuration;
      const webhookConfig = configuration?.forms?.reportIssue?.config;
      const getUserProfile = await AuthService.getUserProfile();
      const reporter_email = getUserProfile.email;
      const webhookUrl = process.env.REACT_APP_N8N_WEBHOOK_URL;
      const reporter_name = getUserProfile?.firstName
        ? `${getUserProfile.firstName}${
            getUserProfile?.lastName ? ` ${getUserProfile.lastName}` : ""
          }`
        : getUserProfile?.email;

      if (!webhookUrl) {
        console.error("Webhook URL not found in configuration");
        return null;
      }

      const postdata = {
        reporter_email,
        reporter_name,
        requestTypeId: webhookConfig?.requestTypeId,
        serviceDeskId: webhookConfig?.serviceDeskId,
        templateFormId: webhookConfig?.templateFormId,
        ...payload,
      };
      const response = await post(webhookUrl, postdata);
      return response;
    } catch (error) {
      console.error("Error submitting issue:", error);
      throw error;
    }
  },
}));

export default useReportIssueStore;
