import { get, patch, post } from "utils/privateHttp.utils";
import moment from "moment";

import { AnnotationType, IdentityType } from "constants/index";
import { TrialStatus, updateTrialStatus } from "./cde.service";
import { CardSize, CardStatus } from "components/Card";
import { buildLocalUrl } from "utils/url.utils";
import { ICreateRpcEvent } from "./fleet.service";
import { getAdjustedTime } from "utils/timer.utils";

const ANNOTATION_SERVICE_ROUTES = {
  ANNOTATION_SERVICE_BASE_CLIENT_PATH:
    "/annotation-service/annotation-service/rest/v1/client/",
  ANNOTATION_SERVICE_BASE_ADMIN_PATH:
    "/annotation-service/annotation-service/rest/v1/admin/",
  FETCH_ANNOTATIONCONFIG_DATA:
    "/annotation-service/annotation-service/rest/v1/client/annotation-configurations",
  CREATE_ANNOTATION:
    "/annotation-service/annotation-service/rest/v1/client/annotations ",
  GET_ANNOTATION:
    "/annotation-service/annotation-service/rest/v1/client/annotations/:annotationId",
  UPDATE_ANNOTATION:
    "/annotation-service/annotation-service/rest/v1/client/annotations/:annotationId",
  GET_PARTICIPANT_ANNOTATION:
    "/annotation-service/annotation-service/rest/v1/client/annotations/:pui/statuses",
  GET_ANNOTATION_ANALYTICS_BY_IDENTITY:
    "/annotation-service/annotation-service/rest/v1/client/analytics/annotations/totals",
  GET_ENGAGEMENT_ANALYTICS_BY_IDENTITY:
    "/annotation-service/annotation-service/rest/v1/client/analytics/annotations/engagements/totals",
  CREATE_EVENT:
    "/annotation-service/annotation-service/rest/v1/client/annotations/:annotationId/events",
  CREATE_INGEST:
    "/annotation-service/annotation-service/rest/v1/client/annotations/:annotationId/ingests",
  GET_INGEST:
    "/annotation-service/annotation-service/rest/v1/client/annotations/ingests/:IngestId",
  GET_INGEST_STATUS:
    "/annotation-service/annotation-service/rest/v1/client/annotations/:annotationId/ingests/:IngestId/status",
  GET_NTP: "/annotation-service/annotation-service/rest/v1/ntp",
};

export interface Product {
  cid: string;
  uuid: string;
  acId: string;
  name: string;
  description: string;
  serialNumbers: string[];
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
}

interface DownloadButton {
  name: string;
  type: string;
  label: string;
  background?: string;
  size?: CardSize;
  options: {
    actionLabel: string;
  };
}

export interface CardConfiguration {
  status: string;
  background: string;
  label: string;
  showIcon: boolean;
  size: CardSize;
  type?: string;
  options?: any;
  show?: boolean;
  name?: string;
  button?: DownloadButton;
  stepNumber?: string | number;
}

export enum IndicatorType {
  "INDICATOR" = "INDICATOR",
  "GAUGE_LINEAR" = "GAUGE_LINEAR",
}
export interface GaugeLinearStepOption {
  icon: string;
  value: number;
  status: CardStatus;
  label: string;
}

export interface GaugeLinearOptions {
  range: {
    start: number;
    stop: number;
  };
  steps: GaugeLinearStepOption[];
}
export interface DeviceValidate {
  title: string;
  params: string[];
  settlingTime?: number;
  timeout?: number;
  background: string;
  type?: string;
  options?: GaugeLinearOptions;
  isVisible?: boolean;
  controlsState?: boolean;
}

export interface EventValidateRules {
  title: string;
  params: string[];
  background: string;
  tooltip?: string;
  type: string;
  actions?: { name: string; params: {} }[];
  isVisible?: boolean;
  controlsState?: boolean;
  showValueOnHoover?: boolean;
  showValue?: boolean;
  rules?: signalRules[];
}

export interface EventValidate {
  type: string;
  component?: string;
  orientation: string;
  rules: EventValidateRules[];
  actions?: { name: string; params: {} }[];
  isVisible?: boolean;
  controlsState?: boolean;
}

export interface AnnotationTimeout {
  value: number;
  action: string;
  visible?: boolean;
  settleTime?: number;
}

export interface AnnotationActions {
  executeInStatus?: string[];
  color: string;
  label: string;
  action: string;
  actions: string[];
  rpc: ICreateRpcEvent[];
  showInStatuses?: string[];
  afterPurgeRedirectTo?: string;
  id?: string;
  show?: boolean;
}

export interface AnnotationTimerActions {
  time: number;
  actions: string[];
  showInStatuses: string[];
}

export interface AnnotationStopOn {
  status: string[];
  action: string;
}

export interface AnnotationTimer {
  direction: string;
  actions: AnnotationTimerActions[];
  isVisible?: boolean;
  timeout?: number;
}

export interface Progress {
  cards: CardConfiguration[];
}

export enum resultType {
  PERCENTAGE = "PERCENTAGE",
  STRING = "STRING",
}
interface resultCards {
  type: string;
  name: string;
  background: string;
  showIcon: boolean;
  showResult: resultType;
  label: string;
  rules: MetricsRule[];
  status: CardStatus;
}

interface AnnotationTerminateAction {
  label: string;
  actions: string[];
}

export interface MetricsRule {
  max?: number;
  min?: number;
  comparisonType: string;
  label?: string;
  size?: CardSize;
  type: string;
  status: string;
  value?: number;
}

export interface ContentAsset {
  url: string;
  alt: string;
  timeout?: number;
}

export interface ContentConfiguration {
  title: string;
  subTitle?: string;
  assets?: {
    pending: ContentAsset[];
    success: ContentAsset[];
    warning: ContentAsset[];
    fail: ContentAsset[];
    progress: ContentAsset[];
  };
  description: {
    fail: string;
    pending: string;
    success: string;
    warning: string;
    progress: string;
  };
}

export interface signalRules {
  icon: string;
  value?: string[];
  status: AnnotationStatus;
  telemetryType: string;
  comparisonType: string;
  max?: number;
  min?: number;
}

export interface IndicatorValidation {
  signalStrength: {
    [key: string]: {
      verdict: boolean;
      max: number;
      min: number;
      icon: string;
      status: CardStatus;
      telemetryType: string;
      comparisonType: string;
      value: number;
    };
  };
  deviceValidation?: {
    verdict: boolean;
    results: DeviceValidationResult[];
  };
}

export interface DeviceValidationResult {
  name: string;
  verdict: boolean;
  tooltip: string;
  isVisible: boolean;
  controlsState: boolean;
  results: {
    [key: string]: {
      verdict: boolean;
      max: number;
      min: number;
      telemetryType: string;
      comparisonType: string;
      value: number;
    };
  };
}

export interface HeadingConfiguration {
  validate: {
    type: string;
    rules: {
      params?: string[];
      tooltip: string;
      background?: string;
      rules?: signalRules[];
      showValue?: boolean;
    }[];
    orientation: "RIGHT" | "LEFT";
  };
}

export interface AnnotationEvent {
  rpc: any[];
  annotationStatus: AnnotationStatus;
  content: any;
  isVisible: boolean;
  name: string;
  timeout: AnnotationTimeout;
  nextEvent: string;
  allowedEvents: string[];
  progress: Progress;
  cards: CardConfiguration[];
  actions?: AnnotationActions[];
  validate: EventValidate;
  stopOn: AnnotationStopOn;
  timer: AnnotationTimer;
  status: CardStatus;
  resultCards?: resultCards[];
  terminateAction?: AnnotationTerminateAction;
  heading?: HeadingConfiguration;
}

export interface Rule {
  max: number;
  min: number;
  value: string | number | boolean | string[];
  telemetryType: string;
  comparisonType: string;
  show: boolean;
  label: string;
  background: string;
  size?: CardSize;
}
export interface Device {
  rules: Rule[];
}

export interface AnnotationConfigurationData {
  forms: any[];
  device: any;
  events: any[];
  result: any;
  options: any;
  translations: any;
  cdeId?: string;
}

export interface AnnotationConfiguration {
  description: string;
  name: string;
  type: "ANNOTATION";
  data: AnnotationConfigurationData;
  uuid: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
}

export interface AnnotationConfigurations {
  cid: string;
  uuid: string;
  name: string;
  description: string;
  configurations: AnnotationConfiguration[];
  acGroupProducts: Product[];
  default: string;
  metadata: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
}

export enum AnnotationStatus {
  PENDING = "PENDING",
  COMPLETE = "COMPLETE",
  FAILED = "FAILED",
  TERMINATED = "TERMINATED",
  TIMEOUT = "TIMEOUT",
  IN_PROGRESS = "IN_PROGRESS",
}

export interface CreateAnnotation {
  trialStatus: TrialStatus;
  trialId: string;
  participantUuid: string;
  configurationId: string;
  serialNumbers: string[];
  metadata: object;
  event: CreateAnnotationEvent[] | [];
  elapsedTime: number;
}

export interface CreateAnnotationEvent {
  name: string;
  description?: string;
  createdAt?: number | string;
}

export interface CreateEvent extends CreateAnnotationEvent {
  annotationId: string;
}

export enum IngestStatus {
  PENDING = "PENDING",
  COMPLETE = "COMPLETE",
  FAILED = "FAILED",
  TERMINATED = "TERMINATED",
  IN_PROGRESS = "IN_PROGRESS",
}

export interface UpdateAnnotation {
  annotationId: string;
  metadata: object;
}

export const fetchAnnotationConfigData = async (
  uuid: string
): Promise<AnnotationConfigurations> => {
  const route = buildLocalUrl({
    path: ANNOTATION_SERVICE_ROUTES.FETCH_ANNOTATIONCONFIG_DATA,
    params: { uuid },
  });
  const response = await get(route);
  return response;
};

export const createAnnotation = async (payload: CreateAnnotation) => {
  const {
    trialStatus,
    trialId,
    participantUuid,
    configurationId,
    serialNumbers,
    metadata,
    event,
    elapsedTime = 0,
  } = payload;
  if (trialStatus !== TrialStatus.IN_PROGRESS) {
    await updateTrialStatus({
      uuid: trialId,
      status: TrialStatus.IN_PROGRESS,
    });
  }

  const currentUTCTime = getAdjustedTime(elapsedTime);

  event.forEach((_event: CreateAnnotationEvent) => {
    if (_event.createdAt !== undefined) {
      _event.createdAt = moment
        .utc(currentUTCTime.valueOf() + (_event.createdAt as number) * 1000)
        .toISOString();
    }
  });

  const route = buildLocalUrl({
    path: ANNOTATION_SERVICE_ROUTES.CREATE_ANNOTATION,
  });
  return await post(route, {
    configurationId,
    serialNumbers,
    metadata,
    type: AnnotationType.ANNOTATION,
    identities: [
      { type: IdentityType.PARTICIPANT, reference: participantUuid },
      { type: IdentityType.TRIAL, reference: trialId },
    ],
    event,
  });
};

export const getAnnotation = async (annotationId: string | undefined) => {
  if (!annotationId) return;
  const route = buildLocalUrl({
    path: ANNOTATION_SERVICE_ROUTES.GET_ANNOTATION,
    params: {
      annotationId,
    },
  });
  return await get(route);
};

export const getParticipantAnnotation = async (
  pui: string,
  queryParams: {
    status?: string;
    skip?: number;
    take?: number;
    search?: string;
    expand?: string;
  }
) => {
  const route = buildLocalUrl({
    path: ANNOTATION_SERVICE_ROUTES.GET_PARTICIPANT_ANNOTATION,
    params: { pui },
    query: queryParams,
  });
  return await get(route);
};

export const getAnnotationAnalyticsByIdentity = async (
  uuid: string | undefined,
  options?: { from?: string; to?: string }
) => {
  if (!uuid) return;
  const route = buildLocalUrl({
    path: ANNOTATION_SERVICE_ROUTES.GET_ANNOTATION_ANALYTICS_BY_IDENTITY,
    query: {
      identities: uuid,
      ...(options?.from ? { from: options.from } : null),
      ...(options?.to ? { to: options.to } : null),
    },
  });
  return await get(route);
};

export const getEngagementAnalyticsByIdentity = async (
  uuid: string | undefined,
  options?: { from?: string; to?: string; durationUnit: string }
) => {
  if (!uuid) return;
  const route = buildLocalUrl({
    path: ANNOTATION_SERVICE_ROUTES.GET_ENGAGEMENT_ANALYTICS_BY_IDENTITY,
    query: {
      identities: uuid,
      ...(options?.from ? { from: options.from } : null),
      ...(options?.to ? { to: options.to } : null),
      ...(options?.durationUnit
        ? { durationUnit: options.durationUnit }
        : null),
    },
  });
  return await get(route);
};

export const createEvent = async (payload: CreateEvent) => {
  const { name, description, annotationId } = payload;
  const route = buildLocalUrl({
    path: ANNOTATION_SERVICE_ROUTES.CREATE_EVENT,
    params: {
      annotationId,
    },
  });
  return await post(route, {
    name,
    description,
  });
};

export const createIngest = async (annotationId: string) => {
  const route = buildLocalUrl({
    path: ANNOTATION_SERVICE_ROUTES.CREATE_INGEST,
    params: {
      annotationId,
    },
  });
  return await post(route, {});
};

export const getIngestStatus = async (
  annotationId: string,
  IngestId: string
) => {
  const route = buildLocalUrl({
    path: ANNOTATION_SERVICE_ROUTES.GET_INGEST_STATUS,
    params: {
      annotationId,
      IngestId,
    },
  });
  return await get(route);
};

export const getIngest = async (IngestId: string | undefined) => {
  if (!IngestId) return;
  const route = buildLocalUrl({
    path: ANNOTATION_SERVICE_ROUTES.GET_INGEST,
    params: {
      IngestId,
    },
  });
  return await get(route);
};

export const updateAnnotation = async (payload: UpdateAnnotation) => {
  const { metadata, annotationId } = payload;
  const route = buildLocalUrl({
    path: ANNOTATION_SERVICE_ROUTES.UPDATE_ANNOTATION,
    params: {
      annotationId,
    },
  });
  return await patch(route, {
    metadata,
  });
};

export const fetchUtcTime = async () => {
  try {
    const route = buildLocalUrl({
      path: ANNOTATION_SERVICE_ROUTES.GET_NTP,
    });
    const response = await get(route);
    return response.currentTime;
  } catch (err) {
    console.error("Error fetching UTC time:", err);
    return moment();
  }
};

export const getTimeDifference = async () => {
  const fetchedUtcTime = await fetchUtcTime();
  const fetchLocalTime = moment.utc();
  const differenceInMilliseconds = fetchLocalTime.diff(fetchedUtcTime);
  return differenceInMilliseconds;
};
