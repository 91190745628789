import React, { ReactNode } from "react";
import { Grid2 as Grid, Typography } from "@mui/material";
import { CSSProperties } from "styled-components";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { TableCell, TableContainer, TableHeader, TableRow } from "./styles";
import { SortOrder } from "constants/index";
import { useIsMobile } from "hooks/media.hook";

type Column = {
  key: string;
  label: string;
  sortable?: {
    defaultDirection?: SortOrder;
    onColumnClick: (key: any) => void;
  };
  width?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
  sx?: CSSProperties;
};

type Data = {
  [key: string]: ReactNode;
};

interface CustomTableProps {
  columns: Column[];
  data: Data[];
  hideHeaderOnMobile?: boolean;
}

const Table: React.FC<CustomTableProps> = ({
  columns,
  data,
  hideHeaderOnMobile,
}) => {
  const isMobile = useIsMobile();
  return (
    <TableContainer>
      {isMobile && hideHeaderOnMobile ? null : (
        <TableHeader container>
          {columns.map((column) => (
            <Grid size={column.width} key={column.key} sx={column.sx}>
              <TableCell
                onClick={() =>
                  column.sortable && column?.sortable?.onColumnClick(column.key)
                }
              >
                <Typography>{column.label}</Typography>
                {column.sortable ? (
                  column.sortable?.defaultDirection === SortOrder.DESC ? (
                    <ArrowDropDownIcon />
                  ) : (
                    <ArrowDropUpIcon />
                  )
                ) : null}
              </TableCell>
            </Grid>
          ))}
        </TableHeader>
      )}
      {data.map((row, index) => (
        <TableRow
          container
          key={index}
          spacing={isMobile ? 1 : 0}
          sx={{ padding: "0.5rem" }}
        >
          {columns.map((column) => (
            <Grid
              flexGrow={1}
              size={column.width}
              key={column.key}
              sx={column.sx}
            >
              <TableCell>{row[column.key]}</TableCell>
            </Grid>
          ))}
        </TableRow>
      ))}
    </TableContainer>
  );
};

export default Table;
