import * as React from "react";
import { Paginator } from "components/Paginator.component";
import { useMemo, useState, useCallback } from "react";
import { find } from "utils/lodashHelpers";
import NotFoundSearch from "components/NotFoundSearch";
import { devicePerPage } from "constants/index";
import DevicesListActionsHandler from "./DevicesListActionsHandler.component";
import { useDeviceStore } from "../device.store";
import Loading from "components/Loading";

interface ManageDevicesProps {
  devices: Record<string, any>[];
  deviceData: {
    serialNumber: string;
    deviceStatusObj: { sn: string; state: string };
    isDeviceReadyObj: { label: string; status: string };
    isDeviceRulePassed: boolean;
    validationResults: any;
  };
  actions: { [key: string]: (...args: any) => void };
  status: string;
  input: string;
  searchSerialNumbers: string[];
  handleOpenExperimentFormModal: () => void;
  showPrechecks: boolean;
  isButtonDisabled?: boolean | false;
  hideActionButton?: boolean;
}

const DevicesList: React.FC<ManageDevicesProps> = ({
  devices,
  actions,
  deviceData,
  status,
  input,
  searchSerialNumbers,
  handleOpenExperimentFormModal,
  showPrechecks,
  isButtonDisabled,
  hideActionButton = false,
}) => {
  const setLoading = useDeviceStore((state) => state.setLoading);
  const loading = useDeviceStore((state) => state.loading);
  const statusArray = useMemo(
    () => status.split(",").map((s) => s.trim()),
    [status]
  );

  const filteredDevices = useMemo(
    () =>
      devices.filter((device) => {
        const deviceFilterData = find(deviceData, {
          serialNumber: device.serialNumber,
        });
        if (
          searchSerialNumbers.includes(device.serialNumber) ||
          (searchSerialNumbers.length === 0 && !input)
        ) {
          setLoading(false);
          return (
            deviceFilterData &&
            statusArray.includes(deviceFilterData?.isDeviceReadyObj?.label)
          );
        }
        setLoading(false);
        return null;
      }),
    [devices, deviceData, statusArray]
  );

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = useMemo(
    () => Math.ceil(searchSerialNumbers.length / devicePerPage),
    [searchSerialNumbers]
  );

  const paginatedDevicesData = useMemo(
    () =>
      filteredDevices.slice(
        (currentPage - 1) * devicePerPage,
        currentPage * devicePerPage
      ),
    [currentPage, filteredDevices]
  );

  const handlePageChange = useCallback(
    (event: React.ChangeEvent<unknown>, page: number) => {
      setCurrentPage(page);
    },
    []
  );

  React.useEffect(() => {
    setCurrentPage(1);
  }, [input]);

  return !loading ? (
    <React.Fragment>
      {searchSerialNumbers.length ? (
        paginatedDevicesData.length ? (
          <DevicesListActionsHandler
            paginatedDevicesData={paginatedDevicesData}
            showPrechecks={showPrechecks}
            actions={actions}
            handleOpenExperimentFormModal={handleOpenExperimentFormModal}
            isButtonDisabled={isButtonDisabled}
            searchSerialNumbers={searchSerialNumbers}
            input={input}
            deviceData={deviceData}
            hideActionButton={hideActionButton}
          />
        ) : null
      ) : (
        <NotFoundSearch
          message="devices.searchSection.notFound.message"
          data={{ key: "", value: "" }}
        />
      )}
      {filteredDevices.length > devicePerPage ? (
        <Paginator
          total={totalPages}
          page={currentPage}
          show={totalPages > 1}
          onChange={handlePageChange}
        />
      ) : null}
    </React.Fragment>
  ) : (
    <Loading />
  );
};

export default DevicesList;
