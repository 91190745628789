import { Grid2 as Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomDrawer from "components/CustomDrawer";
import { ConfigurationService } from "services/configuration.service";
import { FieldConfig } from "utils/form.utils";
import FormContainer from "components/FormContainer";
import { FormikFormProps } from "formik";
import useSnackbarStore from "serviceComponents/Snackbar/snackbar.store";
import useReportIssueStore, { ReportIssueFormData } from "./reportIssue.store";

interface GuideProps {
  isOpen: boolean;
  onClose: () => void;
}

const ReportIssue: React.FC<GuideProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const configuration = ConfigurationService.configuration;
  const reportIssueFormFields = configuration?.forms?.reportIssue.fields;
  const httpErrorNotification = useSnackbarStore(
    (state) => state.httpErrorNotification
  );
  const successNotification = useSnackbarStore((state) => state.openSuccess);
  const setReportIssueFormOpen = useReportIssueStore((state) => state.setOpen);
  const submitIssue = useReportIssueStore((state) => state.submitIssue);
  const serialNumbers = useReportIssueStore((state) => state.serialNumbers);
  const snToOptions = serialNumbers.map((s) => ({ key: s, value: s }));
  const schemaData: FieldConfig[] = reportIssueFormFields;

  const callbackFunctions: Record<string, () => any[]> = {
    fetchSerialNumberOptions: () => snToOptions
  };
  
  if (schemaData) {
    schemaData.forEach((field) => {
      if (field.getOptions) {
        const callbackFunction = callbackFunctions[field.getOptions];
        field.options = callbackFunction?.() || [];
      }
    });
  }
  
  const initialValues = schemaData.reduce(
    (acc, { name }) => ({ ...acc, [name]: "" }),
    {}
  );

  const onSubmit = async (values: FormikFormProps) => {
    const [response] = await submitIssue(values as ReportIssueFormData);
    response?.error
      ? httpErrorNotification(response)
      : successNotification(response?.successLocalId);
    setReportIssueFormOpen(false);
  };

  return (
    <>
      <CustomDrawer
        open={isOpen}
        onClose={onClose}
        additionalProps={{
          titleText: t("reportIssue.header.title.label"),
          id: "reportIssue",
          headerWidth: 12,
        }}
      >
        <Grid
          container
          sx={{ padding: "1.5rem" }}
          flexDirection="column"
          gap={"2rem"}
        >
          <Grid>
            <FormContainer
              schemaData={schemaData}
              initialValues={initialValues}
              onSubmit={onSubmit}
              onClose={onClose}
              labels={{ preSubmit: "submit", postSubmit: "submitting" }}
            />
          </Grid>
        </Grid>
      </CustomDrawer>
    </>
  );
};

export default ReportIssue;
