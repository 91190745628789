import { ErrorMessage, useField } from "formik";
import TextError from "./TextError";
import { FormControl } from "@mui/material";
import TextLabel from "../TextLabel";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

interface TextareaProps {
  label: string;
  name: string;
  isrequired?: boolean;
  [key: string]: any;
}

const Textarea = (props: TextareaProps) => {
  const theme = useTheme();
  const { label, placeholder, name, isrequired, ...rest } = props;
  const { t } = useTranslation();
  const [field, meta] = useField(name);

  const borderColor = meta.touched && meta.error ? theme.palette.error.main : theme.palette.grey[300];

  return (
    <FormControl sx={{ width: "96%" }}>
      {label && <TextLabel variant="h6" label={label} required={isrequired} />}
      <textarea
        id={name}
        style={{
          width: "100%",
          border: `0.1rem solid ${borderColor}`,
          height: "8rem",
          padding: "0.8rem",
          fontSize: "1rem",
          fontFamily: theme.typography.fontFamily,
          fontWeight: 400,
          borderRadius: "0.5rem",
          outline: "none",
        }}
        placeholder={t(placeholder)}
        {...field}
        {...rest}
      />
      <ErrorMessage
        name={name}
        render={(msg: string) => <TextError>{msg}</TextError>}
        className="error"
      />
    </FormControl>
  );
};

export default Textarea;
