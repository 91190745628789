import { useEffect } from "react";
import { CardStatus } from "components/Card/Card.component";
import { useExperimentStore, ValidationResult } from "stores/experiment.store";
import { ExperimentActions } from "../experiment.dto";
import { EventValidateRules } from "services/annotation.service";

interface ExecuteActionsBlockProps {
  status: CardStatus;
  actions: ExperimentActions;
}

const ExecuteActions: React.FC<ExecuteActionsBlockProps> = ({
  status,
  actions,
}) => {
  const currentEvent = useExperimentStore((state) => state.currentEvent);
  const validation = useExperimentStore((state) => state.validation);
  const setEventStatus = useExperimentStore((state) => state.setEventStatus);
  const eventStatus = useExperimentStore((state) => state.eventStatus);

  const validateResults = (
    rules: EventValidateRules[],
    results: ValidationResult[]
  ): boolean => {
    if (!rules?.length || !results?.length) return true;
    const ruleTitles = new Set(rules.map((rule) => rule.title));
    return results.every((result) => ruleTitles.has(result.name));
  };

  const executeAction = async (name: string, params: object) => {
    if (actions[name]) {
      try {
        await actions[name](status, params);
      } catch (error) {
        console.error(`Error executing action "${name}":`, error);
      }
    } else {
      console.error(`Action "${name}" is not defined.`);
    }
  };

  useEffect(() => {
    const executeActions = async () => {
      if (!currentEvent?.actions) return;
      if (!validation.results) return;
      setEventStatus(status);
      if (status === eventStatus) return;
      if (
        !validateResults(currentEvent?.validate?.rules, validation?.results)
      ) {
        return;
      }

      for (const action of currentEvent.actions) {
        if (action?.executeInStatus?.includes(status)) {
          for (const subAction of action.actions) {
            const { name, params } = subAction as unknown as {
              name: string;
              params: object;
            };
            await executeAction(name, params);
          }
        }
      }
    };
    executeActions();
  }, [currentEvent, status, validation]);

  return null;
};

export default ExecuteActions;
