import {
  AnnotationStatus,
  DeviceReadyStatus,
  DeviceStatusEnum,
} from "constants/index";
import { get, isArray } from "utils/lodashHelpers";
import {
  PendingIcon,
  SuccessIcon,
  TerminateIcon,
  ProgressIconPie,
} from "components/icons";
import HourglassBottomRoundedIcon from "@mui/icons-material/HourglassBottomRounded";
import { DeviceStateEnum } from "constants/index";

import {
  EventValidate,
  Rule,
  MetricsRule,
  AnnotationEvent,
} from "services/annotation.service";

import { Metrics } from "services/fleet.service";
import { Validation, ValidationResult } from "stores/experiment.store";
import {
  PurgeEventConfiguration,
  ValidationRules,
} from "pages/PurgeDevice/purge.store";
import { Configuration } from "services/configuration.service";

function validateRules(
  validationRules: EventValidate | ValidationRules,
  rulesCheckResults: any,
  metrics?: { [key: string]: string | number | boolean }
) {
  if (!validationRules?.rules?.length)
    return {
      verdict: true,
      results: rulesCheckResults,
    };
  const validationRulesRules = validationRules.rules.flatMap(
    (item) => item?.rules || []
  );
  const signalStrength = metrics
    ? checkSignalRules(validationRulesRules, metrics)
    : {};
  const results = validationRules.rules.reduce((acc: any[], rule) => {
    if (rule.rules) {
      Object.entries(signalStrength).forEach(([key, value]: any) => {
        acc.push({
          name: value.telemetryType,
          verdict: value.verdict,
          background: value.icon ?? value.background,
          tooltip: rule.tooltip,
          showValueOnHoover: rule?.showValueOnHoover,
          showValue: rule?.showValue,
          isVisible: true,
          controlsState: true,
          results: { [value.telemetryType]: value },
          status: value.status,
          signalStrength: true,
        });
      });
      return acc;
    }

    if (!rule.params) return acc;
    const { background, tooltip, showValueOnHoover, showValue } = rule;
    const ruleSummary = rule.params.some((param: string) => {
      return !rulesCheckResults[param]?.verdict;
    });

    const controlsState = rule?.controlsState ?? true;
    const isVisible = rule?.isVisible ?? true;
    acc.push({
      name: rule.title ? rule.title : rule.params[0],
      verdict: !ruleSummary,
      background,
      tooltip,
      showValueOnHoover,
      showValue,
      isVisible,
      controlsState,
      results: rule.params.reduce(
        (acc: any, param: string) => ({
          ...acc,
          [param]: rulesCheckResults[param],
        }),
        {}
      ),
    });
    return acc;
  }, [] as any[]);
  return {
    verdict: !results.some(
      (result: any) =>
        result.verdict === false && result.controlsState !== false
    ),
    results,
  };
}

function deviceStateChecker(
  deviceStatus: string,
  isRulePassed: boolean,
  isDeviceRulePassed: boolean
) {
  switch (deviceStatus) {
    case DeviceStatusEnum.ONLINE:
      if (isRulePassed && isDeviceRulePassed) {
        return {
          label: DeviceStateEnum.Ready,
          status: DeviceReadyStatus.COMPLETE,
        };
      }
      return {
        label: DeviceStateEnum.Preparing,
        status: DeviceReadyStatus.PENDING,
      };

    case DeviceStatusEnum.OFFLINE:
      return {
        label: DeviceStateEnum.Unavailable,
        status: DeviceReadyStatus.WARNING,
      };

    case DeviceStatusEnum.ERROR:
      return {
        label: DeviceReadyStatus.ERROR,
        status: DeviceReadyStatus.ERROR,
      };

    default:
      return {
        label: DeviceStateEnum.Preparing,
        status: DeviceReadyStatus.PENDING,
      };
  }
}

const getDeviceStatus = (
  inUse: string | undefined,
  rules: Record<string, any>,
  device: Record<string, any>
) => {
  if (
    (inUse === AnnotationStatus.IN_PROGRESS ||
      inUse === AnnotationStatus.PENDING) &&
    device.status !== DeviceStatusEnum.OFFLINE
  ) {
    return {
      label: DeviceStateEnum.InUse,
      status: DeviceReadyStatus.INUSE,
      isDeviceRulePassed: false,
      validationResults: validateRules(
        rules.device.validate,
        false,
        device.state.metrics
      ),
    };
  }

  const isRulePassed = checkRules(
    rules.device.rules,
    device.state.metrics as unknown as Metrics
  );

  const validationResults = validateRules(
    rules.device.validate,
    isRulePassed,
    device.state.metrics
  );

  return {
    ...deviceStateChecker(
      device?.status,
      !!isRulePassed,
      validationResults.verdict
    ),
    isDeviceRulePassed: validationResults.verdict,
    validationResults,
  };
};

function checkRules(
  deviceRules: Rule[],
  metric: { [key: string]: string | number | boolean }
) {
  const verdicts = deviceRules.reduce((acc, rule) => {
    const value = get(metric, rule.telemetryType);
    const verdict = deviceRulesCases(rule, value);
    acc = {
      ...acc,
      [rule.telemetryType]: { verdict, ...rule, value },
    };
    return acc;
  }, {});
  return verdicts;
}

function checkSignalRules(
  deviceRules: any[],
  metric: { [key: string]: string | number | boolean }
) {
  let verdicts = {};
  for (const rule of deviceRules) {
    const value = get(metric, rule.telemetryType);
    const verdict = deviceRulesCases(rule, value);
    if (verdict) {
      verdicts = {
        ...verdicts,
        [rule.telemetryType]: { verdict, ...rule, value },
      };
      break;
    }
  }
  return verdicts;
}

function deviceRulesCases(
  ruleObj: Rule | MetricsRule,
  value: string | number | boolean | number[] | any
): boolean {
  if (!ruleObj) return false;
  if (value == null) return false;
  switch (ruleObj.comparisonType) {
    case "MIN_MAX":
      try {
        if (typeof value === "object") {
          throw new Error("Type error, provide string | number");
        }
        if (
          typeof ruleObj.min === "undefined" ||
          typeof ruleObj.max === "undefined"
        ) {
          return false;
        }
        const evalValue = Number(value);
        const isMIN_MAXPassed =
          evalValue >= ruleObj.min && evalValue <= ruleObj.max;
        return isMIN_MAXPassed;
      } catch (e: any) {
        const message = {
          message: e.message,
          error: e,
          input: {
            ruleObj,
            value,
          },
        };
        throw message;
      }
    case "MIN_MAX_ARRAY":
      try {
        if (!Array.isArray(value)) {
          throw new Error("Type error, provide array of numbers is expected");
        }
        const sum =
          (value as number[])?.reduce((acc, value) => acc + Number(value), 0) ||
          0;
        const average = sum / value.length;
        const isMIN_MAX_ARRAYPassed =
          average &&
          Number(ruleObj.min) <= average &&
          average <= Number(ruleObj.max)
            ? true
            : false;
        return isMIN_MAX_ARRAYPassed;
      } catch (e: any) {
        const message = {
          message: e.message,
          error: e,
          input: {
            ruleObj,
            value,
          },
        };
        throw message;
      }
    case "EXACT":
      try {
        if (typeof value === "object") {
          const isEXACTPassed = value.status === ruleObj.value;
          return isEXACTPassed;
        }
        const isEXACTPassed = value === ruleObj.value;

        return isEXACTPassed;
      } catch (e: any) {
        const message = {
          message: e.message,
          error: e,
          input: {
            ruleObj,
            value,
          },
        };
        throw message;
      }
    case "INCLUDES":
      try {
        const isINCLUDESPassed =
          isArray(ruleObj.value) && ruleObj.value.includes(value);

        return isINCLUDESPassed;
      } catch (e: any) {
        const message = {
          message: e.message,
          error: e,
          input: {
            ruleObj,
            value,
          },
        };
        throw message;
      }

    default:
      return false;
  }
}

const statusIcon: Record<DeviceStateEnum, React.ElementType> = {
  [DeviceStateEnum.All]: PendingIcon,
  [DeviceStateEnum.Ready]: SuccessIcon,
  [DeviceStateEnum.Unavailable]: TerminateIcon,
  [DeviceStateEnum.Preparing]: ProgressIconPie,
  [DeviceStateEnum.InUse]: HourglassBottomRoundedIcon,
};

const hasControlsState = (validation: Validation) =>
  validation?.results
    ? validation.results?.some(
        ({ controlsState }: ValidationResult) => controlsState === false
      ) ?? false
    : false;

const isValidResults = (
  results: ValidationResult[],
  currentEvent: PurgeEventConfiguration | AnnotationEvent
) =>
  results.every(({ verdict, results }) => {
    if (!verdict) return false;
    const telemetryTypes = Object.values(results).map(
      (result: any) => result.telemetryType
    );
    return telemetryTypes.every(
      (telemetryType) =>
        currentEvent &&
        currentEvent?.validate?.rules.some(({ params }) =>
          params.includes(telemetryType)
        )
    );
  });

const getAliasData = (aliasConfig: any, devices: any, serialNumber: string) => {
  return Object.keys(aliasConfig)
    .filter((key) => aliasConfig[key].show)
    .map((key) => {
      return {
        label: `header.title.subItem.label.${key}`,
        text:
          (serialNumber &&
            devices.find(
              (deviceDetail: any) => deviceDetail.serialNumber === serialNumber
            )?.[key]) ||
          null,
      };
    });
};

const getSystemStatusContent = (
  configurationData: Configuration,
  pageName: string
) => {
  const systemStatusRibbon =
    configurationData.pages[pageName]?.content?.notificationRibbon;
  const systemStatusRibbonKey = systemStatusRibbon?.split(".").pop();
  return configurationData?.content?.[systemStatusRibbonKey!];
};

export {
  deviceStateChecker,
  checkRules,
  validateRules,
  statusIcon,
  getDeviceStatus,
  deviceRulesCases,
  hasControlsState,
  isValidResults,
  checkSignalRules,
  getAliasData,
  getSystemStatusContent,
};
