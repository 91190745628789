import React from "react";
import { useTranslation } from "react-i18next";
import { Box, useTheme, Grid2 as Grid } from "@mui/material";
import { StyleTitle, StyleSubTitle } from "./styles";
import Card, { CardStatus, getCardColor } from "components/Card";
import IllustrationCard from "components/IllustrationCard";
import { maxPageWidth } from "constants/index";
import {
  ContentAsset,
  ContentConfiguration,
} from "services/annotation.service";
import SanitizedTypography from "components/Typography/SanitizedTypography.component";
import { CSSProperties } from "styled-components";

export enum backgroundType {
  NONE = "NONE",
  GRADIENT = "GRADIENT",
}

interface InformationBlockProps {
  status: CardStatus;
  configurations?: ContentConfiguration;
  sx?: CSSProperties;
}

const InformationBlock: React.FC<InformationBlockProps> = ({
  status,
  configurations,
  sx,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  if (!status) return null;
  const cardColor = status ? getCardColor(status, theme) : null;
  if (!configurations) return;
  const getInformationBlockContent = (
    status: CardStatus,
    deviceContent: ContentConfiguration
  ) => {
    const { title, subTitle, description, assets } = deviceContent;

    const contentMap: Record<
      CardStatus,
      { description: string; assets: ContentAsset[] }
    > = {
      [CardStatus.SUCCESS]: {
        description: description?.success ?? "",
        assets: assets?.success || assets?.pending || [],
      },
      [CardStatus.FAIL]: {
        description: description?.fail ?? "",
        assets: assets?.fail || [],
      },
      [CardStatus.WARNING]: {
        description: description?.warning ?? "",
        assets: assets?.warning || [],
      },
      [CardStatus.PENDING]: {
        description: description?.pending ?? "",
        assets: assets?.pending || [],
      },
      [CardStatus.INFO]: {
        description: description?.pending ?? "",
        assets: assets?.pending || [],
      },
      [CardStatus.IN_PROGRESS]: {
        description: description?.progress ?? "",
        assets: assets?.progress || assets?.pending || [],
      },
    };

    return {
      title,
      subTitle,
      description: contentMap[status]?.description,
      assets: contentMap[status]?.assets,
    };
  };

  const content = getInformationBlockContent(status, configurations);
  const { title, description, subTitle, assets: illustrations } = content;

  const backgroundStyles = {
    display: "flex",
    minHeight: "17rem",
    borderRadius: 0,
    height: "100%",
    border: "none",
    maxWidth: maxPageWidth,
    width: "100%",
    margin: "1.5rem auto 2rem auto",
    padding: "1rem",
  };

  return (
    <Box sx={{ background: cardColor?.gradient }}>
      <Card
        sx={{
          backgroundColor: "transparent",
          ...backgroundStyles,
        }}
      >
        <Grid
          container
          alignItems="center"
          sx={{ flexGrow: 1 }}
          spacing={0}
          columns={12}
        >
          <Grid
            size={{ xs: 12, sm: title || subTitle || description ? 8 : 12 }}
            sx={{ ...sx }}
          >
            {title && (
              <StyleTitle
                variant="h5"
                component="h1"
                sx={{ color: cardColor?.darker }}
              >
                {t(title)}
              </StyleTitle>
            )}
            {subTitle && (
              <StyleSubTitle variant="subtitle1" component="h2">
                {t(subTitle)}
              </StyleSubTitle>
            )}
            {description && (
              <SanitizedTypography
                variant="body1"
                component="div"
                dangerouslySetInnerHTML={{
                  __html: t(description),
                }}
              />
            )}
          </Grid>
          {illustrations?.length > 0 && (
            <Grid
              size={{ xs: 12, sm: 4 }}
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                maxWidth: { xs: "85%" },
                height: { xs: "auto", sm: "100%" },
              }}
            >
              <IllustrationCard
                status={status}
                illustrations={illustrations}
                sx={{
                  border: "none",
                  backgroundColor: "transparent",
                  backgroundImage: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              />
            </Grid>
          )}
        </Grid>
      </Card>
    </Box>
  );
};

export default InformationBlock;
