import { Field, ErrorMessage, FieldProps, FormikValues } from "formik";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import TextError from "./TextError";
import { FormControl, TextField } from "@mui/material";
import TextLabel from "../TextLabel";
import { useTheme } from "@mui/material/styles";
import { FieldSelectedSections } from "@mui/x-date-pickers";

interface DatePickerProps {
  label: string;
  name: string;
  isrequired?: boolean;
}

const DatePicker = (props: DatePickerProps) => {
  const theme = useTheme();
  const { label, name, isrequired } = props;

  return (
    <FormControl sx={{ width: "100%" }}>
      <TextLabel label={label} required={isrequired} />
      <Field name={name}>
        {({ field, form, meta }: FieldProps<FormikValues>) => {
          const { setFieldValue } = form;
          const { value } = field;
          const dateValue = value ? dayjs(value as any) : null;

          const borderColor =
            form.touched.hasOwnProperty(name) && meta.error
              ? theme.palette.error.main
              : theme.palette.grey[300];

          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MuiDatePicker
                value={dateValue}
                disableFuture={true}
                onChange={(date) => {
                  form.setFieldTouched(name, true);
                  setFieldValue(
                    name,
                    date ? dayjs(date).format("YYYY-MM-DD") : "",
                    true
                  );
                }}
                onSelectedSectionsChange={(newValue: FieldSelectedSections) => {
                  newValue === null && form.setFieldTouched(name, true);
                }}
                slots={{
                  textField: TextField,
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    sx: {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: borderColor,
                        borderRadius: "inherit",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        overflow: "hidden",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused": {
                        borderColor: theme.palette.error.main,
                      },
                      "& .MuiFormHelperText-root": {
                        color: theme.palette.error.dark,
                        margin: 0,
                        fontWeight: 400,
                        fontSize: "1rem",
                      },
                      "& .MuiInputBase-root: hover .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: borderColor,
                        },
                    },
                  },
                }}
              />
            </LocalizationProvider>
          );
        }}
      </Field>
      <ErrorMessage
        name={name}
        render={(msg: string) => <TextError>{msg}</TextError>}
      />
    </FormControl>
  );
};

export default DatePicker;
