import { Grid2 as Grid, ListItemText } from "@mui/material";
import { useState } from "react";
import LanguageDialog from "features/authentication/components/LanguageDialog";
import { useTranslation } from "react-i18next";
import { AuthService } from "services/auth.service";
import { StyleLeftNavigationItem, StyleIconWrapper } from "./layouts.styles";
import { useLeftNavigationStore } from "stores/navigation.store";
import { getAssetsPath } from "utils/assets.utils";
import DialogBox, {
  ButtonTypes,
} from "components/DialogBox/DialogBox.component";
import { useDialogBoxStore } from "components/DialogBox/useDialogBoxStore";
import { LeftNavigationMenuItems } from "constants/index";
import useGuideStore from "components/TroubleShootGuide/guide.store";
import TroubleShootGuide from "components/TroubleShootGuide/TroubleShootGuide.component";
import useReportIssueStore from "components/ReportIssue/reportIssue.store";
import ReportIssue from "components/ReportIssue/ReportIssue.component";

export interface LinksProps {
  open: boolean;
}

const LeftNavigationBottomLinks: React.FC<LinksProps> = ({ open }) => {
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
  const openDialogBox = useDialogBoxStore((state) => state.open);
  const closeDialogBox = useDialogBoxStore((state) => state.close);

  const guideOpen = useGuideStore((state) => state.open);
  const setGuideOpen = useGuideStore((state) => state.setOpen);
  const setGuideSearchInput = useGuideStore((state) => state.setSearchInput);
  const resetGuide = useGuideStore((state) => state.resetGuide);
  const setReportIssueFormOpen = useReportIssueStore((state) => state.setOpen);
  const reportIssueFormOpen = useReportIssueStore((state) => state.open);

  const getNavigationStatesBottom = useLeftNavigationStore(
    (state) => state.getNavigationStatesBottom
  );
  const activeNavigationState = useLeftNavigationStore(
    (state) => state.activeNavigationState
  );

  const { t } = useTranslation();

  const CustomListItem: React.FC<{
    onClick: () => void;
    icon: string;
    text: string;
    open: boolean;
  }> = ({ onClick, icon, text, open }) => {
    return (
      <StyleLeftNavigationItem open={open} onClick={onClick}>
        <Grid container alignItems="center" justifyContent="flex-start">
          {icon && (
            <Grid>
              <StyleIconWrapper>
                <img
                  src={getAssetsPath(icon)}
                  alt={text}
                  style={{ width: "100%" }}
                />
              </StyleIconWrapper>
            </Grid>
          )}
          <Grid sx={{ display: open ? "block" : "none" }}>
            <ListItemText primary={text} />
          </Grid>
        </Grid>
      </StyleLeftNavigationItem>
    );
  };

  const handleLanguageClickOpen = () => {
    setLanguageMenuOpen(true);
  };

  const handleLanguageClose = () => {
    setLanguageMenuOpen(false);
  };

  const handleConfirmAction = async () => {
    await AuthService.logout();
  };

  const handleCloseModal = () => {
    closeDialogBox("logout");
  };
  const handleHelpClick = () => {
    resetGuide();
    setGuideOpen(true);
  };

  const handleReportIssueClick = () => {
    setReportIssueFormOpen(true);
  };

  const handleCloseHelp = () => {
    setGuideSearchInput("");
    setGuideOpen(false);
  };

  const handleCloseReportIssue = () => {
    setReportIssueFormOpen(false);
  };

  const handleIconClick = () => {
    openDialogBox("logout", {
      title: "account.logout.dialog.title",
      description: "account.logout.dialog.description",
      showCloseButton: false,
      buttons: [
        {
          label: "account.logout.dialog.cancel.button.label",
          action: "handleCloseModal",
          type: ButtonTypes.SECONDARY,
        },
        {
          label: "account.logout.dialog.confirm.button.label",
          action: "handleConfirmAction",
          type: ButtonTypes.PRIMARY,
        },
      ],
    });
  };

  if (!getNavigationStatesBottom) return;
  if (!activeNavigationState) return;
  if (!getNavigationStatesBottom[activeNavigationState]) return;
  const activeStateMenuItems = getNavigationStatesBottom[activeNavigationState];
  const filteredNavigationStates = activeStateMenuItems.filter(
    ({ id, orientation, params }) =>
      id !== LeftNavigationMenuItems.SETTINGS ||
      (params?.languages?.length ?? 0) > 1
  );

  const actions = {
    handleCloseModal: handleCloseModal,
    handleConfirmAction: handleConfirmAction,
    handleSettingClick: handleLanguageClickOpen,
    handleLogoutClick: handleIconClick,
    handleHelpClick: handleHelpClick,
    handleReportIssueClick: handleReportIssueClick,
  };

  const languages = filteredNavigationStates.find(
    (items) => items.id === LeftNavigationMenuItems.SETTINGS
  )?.params?.languages || ["en"];

  return (
    <>
      {filteredNavigationStates.map((item) => {
        return (
          <CustomListItem
            key={item.label}
            onClick={actions[item.onClick as unknown as keyof typeof actions]}
            icon={item.icon}
            text={t(item.label)}
            open={open}
          />
        );
      })}
      <LanguageDialog
        open={languageMenuOpen}
        onClose={handleLanguageClose}
        languages={languages}
      />
      <DialogBox dialogKey="logout" actions={actions} />
      <TroubleShootGuide isOpen={guideOpen} onClose={handleCloseHelp} />
      <ReportIssue
        isOpen={reportIssueFormOpen}
        onClose={handleCloseReportIssue}
      />
    </>
  );
};

export default LeftNavigationBottomLinks;
